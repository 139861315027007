<template>
  <div class="wrap">
    <el-form inline size="mini">
      <el-form-item label="Data Type:">
        <select-box
          style="width:240px"
          :options="dataTypeOption"
          :value.sync="extraData.dataType"
        />
      </el-form-item>
      <el-form-item label="Data ID:">
        <el-input v-model="extraData.dataId" placeholder="Please"></el-input>
      </el-form-item>
      <el-form-item label="Data Name:">
        <el-input v-model="extraData.dataName" placeholder="Please"></el-input>
      </el-form-item>
      <el-form-item label="URL:">
        <el-input v-model="extraData.requestUri" placeholder="Please"></el-input>
      </el-form-item>

      <el-form-item label="Date:">
        <date-range type="datetimerange" v-model="dateRange" />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="search"
          >Search</el-button
        >
        <el-button @click="reset">Reset</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="list"
      stripe
      v-loading="loading"
      @sort-change="sort"
      :max-height="tableHeight"
    >
      <el-table-column
        label="Data Type"
        align="center"
        prop="dataType"
      ></el-table-column>
      <el-table-column
        label="Data ID"
        align="center"
        prop="dataId"
      ></el-table-column>
      <el-table-column
        label="Data Name"
        align="center"
        prop="dataName"
      ></el-table-column>
      <el-table-column
        label="Last Operation Time"
        align="center"
        prop="operationTime"
      >
        <template v-slot="{ row }">{{
          parseTime(row.operateTime, '{y}-{m}-{d} {h}:{i}:{s}')
        }}</template>
      </el-table-column>
      <!-- <el-table-column
        label="Operation Type"
        align="center"
        prop="operationType"
      ></el-table-column> -->
      <el-table-column
        label="User"
        align="center"
        prop="operateUserName"
      ></el-table-column>
      <el-table-column label="Operation Details" min-width="160" align="center">
        <template v-slot="{ row }">
          <OperationType :dataType="row.dataType" :dataId="row.dataId" :requestUri="row.requestUri"/>
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="end" style="margin: 20px 0 0 0">
      <el-pagination
        :total="total"
        :current-page="queryData.current"
        :page-size="queryData.size"
        :page-sizes="pageSizes"
        @current-change="changePage"
        @size-change="handleSizeChange"
        :layout="pageLayout"
      ></el-pagination>
    </el-row>
    <!-- <OperationType/> -->
  </div>
</template>
<script>
import { getOperation as getData } from '@/service/config'
import { getAfterDate, parseTime, getDataTimeAfterDate } from '@/utils'
import TableMixin from '@/mixins/subscibe/table'
import DateRange from '@/components/DateRange/dateRange'
import Dropdown from '@/components/Dropdown'
import InputAutocomplete from '@/components/InputAutocomplete'
import { operationOptions, dataTypeOption } from '@/utils/constants'
// import OperationType from '@/components/operationType'
const toDay = getDataTimeAfterDate()

export default {
  name: 'MatchMapping',
  mixins: [TableMixin],
  components: {
    DateRange,
    // OperationType
  },
  beforeCreate() {
    this.timerGroup = {}
  },
  data() {
    return {
      operationOptions,
      dataTypeOption,
      dateRange: [...toDay],
      extraData: {
        dataType: 'Standard_Match',
        dataId: '',
        dataName: '',
        requestUri: '',
        operateStartTime: toDay[0],
        operateEndTime: toDay[1]
      }
    }
  },

  watch: {
    dateRange(val) {
      if (!val) {
        this.extraData.operateStartTime = ''
        this.extraData.operateEndTime = ''
      } else {
        this.extraData.operateStartTime = val[0]
        this.extraData.operateEndTime = val[1]
      }
      this.$nextTick(() => {
        this.search()
      })
    }
  },
  methods: {
    getData,
    parseTime,
    reset() {
      // console.log('reset')
      this.dateRange = getDataTimeAfterDate()
      this.extraData = {
        dataType: 'Standard_Match',
        dataId: '',
        dataName: '',
        requestUri: '',
        operateStartTime: toDay[0],
        operateEndTime: toDay[1]
      }
    }
  }
}
</script>